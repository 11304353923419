@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'NotoSansKR-Regular';
  font-weight: normal;
  src: url('.././fonts/NotoSansKR-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'NotoSansKR-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ck-editor__editable {
  min-height: 500px !important;
}
